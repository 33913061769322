import * as React from "react"
import { graphql } from 'gatsby'
import { withPrismicPreview } from 'gatsby-plugin-prismic-previews'
import { repositoryConfigs } from '../utils/prismic-previews'
import { SliceZones } from '../components/slices/slice-zones'
import { Layout } from "../components/global/layout"
import { MastheadCarousel } from "../components/layout/masthead-carousel"

const IndexPage = ({ data, pageContext }) => {
    if (!data) 
      return null
  
    const document = data.prismicHome

    return (
        <Layout pageTitle={document.data.title.text} 
                metaTitle={document.data.meta_title.text} 
                metaDescription={document.data.meta_description.text || document.data.summary.text}
                shareImage={document.data.share_image.url}>
            <MastheadCarousel data={document.data.masthead} />
            <SliceZones slices={document.data.body} />
        </Layout>
    )
}

export const query = graphql`
  query HomeQuery {
    prismicHome {
      _previewable
      id
      lang
      type
      url
      data {
        title {
          text
        }
        summary {
          text
        }
        meta_title {
          text
        }
        meta_description {
          text
        }
        share_image {
          url
        }
        masthead {
          masthead_subtitle {
            text
          }
          masthead_title {
            text
          }
          masthead_summary {
            text
          }
          masthead_buttonlink {
            url
          }
          masthead_buttontext {
            text
          }
          masthead_image {
            url
          }
        }
        body {
          ... on PrismicHomeDataBodyLogos {
            id
            items {
              logos_image {
                alt
                copyright
                url
                gatsbyImageData
              }
              logos_name {
                text
                html
                raw
              }
            }
            primary {
              logos_title {
                text
              }
              logos_background_image {
                url
              }
            }
            slice_type
          }
          ... on PrismicHomeDataBodyContentWithMedia {
            id
            primary {
              contentmedia_subtitle {
                text
              }
              contentmedia_title {
                text
              }
              contentmedia_content {
                text
              }
              contentmedia_image {
                url
              }
              contentmedia_button_text {
                text
              }
              contentmedia_button_link {
                url
              }
              contentmedia_button2_text {
                text
              }
              contentmedia_button2_link {
                url
              }
              contentmedia_image_alignment
            }
            slice_type
          }
          ... on PrismicHomeDataBodyCallToAction {
            id
            primary {
              cta_button_link {
                url
              }
              cta_button_text
              cta_description {
                text
              }
              cta_title {
                text
              }
            }
            slice_type
          }
          ... on PrismicHomeDataBodyRichTextContent {
            id
            primary {
              richtextcontent_content {
                raw
              }
            }
            slice_type
          }
          ... on PrismicHomeDataBodyTestimonial {
            id
            primary {
              testimonial_quote {
                text
              }
              testimonial_link_text {
                text
              }
              testimonial_link {
                url
              }
            }
            slice_type
          }
          ... on PrismicHomeDataBodyWhyWorkWithUs {
            id
            primary {
              whyworkwithus_point1 {
                text
              }
              whyworkwithus_point2 {
                text
              }
              whyworkwithus_point3 {
                text
              }
              whyworkwithus_title {
                text
              }
            }
            slice_type
          }
          ... on PrismicHomeDataBodyUseCases {
            id
            items {
              usecases_usecase {
                text
              }
            }
            primary {
              usecases_link_text {
                text
              }
              usecases_title {
                text
              }
              usescases_link {
                url
              }
            }
            slice_type
          }
          ... on PrismicHomeDataBodyTestimonialCarousel {
            id
            items {
              testimonialcarousel_author {
                text
              }
              testimonialcarousel_authorjobtitle {
                text
              }
              testimonialcarousel_image {
                url
              }
              testimonialcarousel_quote {
                text
              }
              testimonialcarousel_title {
                text
              }
            }
            primary {
              testimonialcarousel_title {
                text
              }
            }
            slice_type
          }
          ... on PrismicHomeDataBodyHowItWorksCarousel {
            id
            primary {
              howitworkscarousel_title {
                text
              }
            }
            items {
              howitworkscarousel_title {
                text
              }
              howitworkscarousel_summary {
                text
              }
            }
            slice_type
          }
          ... on PrismicHomeDataBodyFeaturedProjects {
            id
            primary {
              featuredprojects_startprojectlink {
                url
              }
              featuredprojects_title {
                text
              }
            }
            items {
              featuredprojects_document {
                document {
                  ... on PrismicProject {
                    id
                    data {
                      image {
                        url
                      }
                      summary {
                        text
                      }
                      title {
                        text
                      }
                    }
                    tags
                  }
                }
              }
            }
            slice_type
          }
          ... on PrismicHomeDataBodyServicesCarousel {
            id
            items {
              servicescarousel_image {
                url
              }
              servicescarousel_circleimage {
                url
              }
              servicescarousel_fullscreenimage {
                url
              }
              servicescarousel_link {
                url
              }
              servicescarousel_navigationtitle {
                text
              }
              servicescarousel_summary {
                text
              }
              servicescarousel_title {
                text
              }
            }
            primary {
              servicescarousel_subtitle {
                text
              }
              servicescarousel_title {
                text
              }
              servicescarousel_summary {
                text
              }
            }
            slice_type
          }
          ... on PrismicHomeDataBodyFeaturedBlogPosts {
            id
            items {
              featuredblogposts_document {
                document {
                  ... on PrismicInsightArticle {
                    id
                    data {
                      listing_image {
                        url
                      }
                      title {
                        text
                      }
                      summary {
                        text
                      }
                    }
                    url
                    tags
                  }
                }
                type
              }
            }
            primary {
              featuredblogposts_title {
                text
              }
            }
            slice_type
          }
          ... on PrismicHomeDataBodyListing {
            id
            primary {
              listing_title {
                text
              }
              listing_buttonlink {
                url
              }
              listing_buttontext {
                text
              }
              listing_button2link {
                url
              }
              listing_button2text {
                text
              }
            }
            items {
              image {
                url
              }
              listing_title {
                text
              }
            }
            slice_type
          }
        }
      }
    }
  }
`

export default withPrismicPreview(IndexPage, repositoryConfigs)

