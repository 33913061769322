import React from "react"
import Slider from "react-slick"

export const CarouselNextArrow = (props) => {
    const { className, onClick } = props;
    return (
        <button className={className} onClick={onClick}>
            <svg fill="none" viewBox="0 0 20 20"><path fillRule="evenodd" clipRule="evenodd" d="M10.407 20 9 18.533 17.187 10 9 1.467 10.407 0 20 9.999 10.407 20Z" fill="#1A1A1A"/><path fill="#1A1A1A" d="M0 11V9h18v2z"/></svg>
        </button>
    );
}

export const CarouselPrevArrow = (props) => {
    const { className, onClick } = props;
    return (
        <button className={className} onClick={onClick}>
            <svg fill="none" viewBox="0 0 20 20"><path fillRule="evenodd" clipRule="evenodd" d="M9.593 0 11 1.467 2.813 10 11 18.533 9.593 20 0 10.001 9.593 0Z" fill="#1A1A1A"/><path fill="#1A1A1A" d="M20 9v2H2V9z"/></svg>
        </button>
    );
}

export const MastheadCarousel = ({ data }) => {
    if (data) {
        const settings = {
            appendDots: dots => (
                <div>
                    <div className="row">
                        <div className="c-masthead__dots">
                            <ul>{dots}</ul>
                        </div>
                    </div>
                </div>
            ),
            autoplay: true,
            autoplaySpeed: 5000,
            dots: true,
            fade: true,
            infinite: true,
            nextArrow: <CarouselNextArrow />,
            prevArrow: <CarouselPrevArrow />,
            slidesToScroll: 1,
            slidesToShow: 1,
            speed: 1000
        };
    
        return (
            <div className="c-masthead c-masthead--media">
                <div className="container">
                    <Slider {...settings}>
                    {data.map((item, i) => {
                                    if (item.masthead_title.text) {
                                        return (
                                            <div key={i}>
                                                <div className="row">
                                                    <div className="c-masthead__container">
                                                        {item.masthead_subtitle.text ? 
                                                            <div className="c-masthead__subtitle">{item.masthead_subtitle.text}</div> : null}
                                                        <h1 className="c-masthead__title">{item.masthead_title.text}</h1>
                                                        {item.masthead_summary.text ? 
                                                            <p className="c-masthead__summary">{item.masthead_summary.text}</p> : null}
                                                        {item.masthead_buttonlink.url && item.masthead_buttontext.text ? 
                                                            <a href={item.masthead_buttonlink.url} className="c-masthead__link c-btn c-btn--large">{item.masthead_buttontext.text}</a> : null}
                                                    </div>
                                                    {item.masthead_image.url ? 
                                                        <div className="c-masthead__media-container">
                                                            <img src={`${item.masthead_image.url}&w=1400`} alt="" className="c-masthead__media" loading="lazy" />
                                                        </div> : null}
                                                </div>
                                            </div>
                                        )
                                    }
                                    else {
                                        return null
                                    }
                              })}
                    </Slider>
                </div>
            </div>
        )
    }

    return null
}

export default MastheadCarousel